
import Nav from './Nav.vue'
import Logo from '@/assets/Logo.vue'

export default {
  components: {
    Nav,
    Logo
  }
}
