
import { defineComponent } from 'vue'
import TimesCircleIcon from '@/assets/icons/TimesCircle.vue'

export default defineComponent({
  name: 'ErrBubble',
  components: {
    TimesCircleIcon
  },
  props: {
    msg: String
  }
})
