
import { defineComponent } from 'vue'
import Glasses from './Glasses.vue'
import SadMouth from './SadMouth.vue'

export default defineComponent({
  name: 'SadFace',
  components: {
    Glasses,
    SadMouth
  }
})
