<template>
  <main>
    <h3>404</h3>
    <h4>Page Not Found</h4>
  </main>
</template>

<style lang="scss" scoped>
main {
  text-align: center;
}
</style>
