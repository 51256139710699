
import { defineComponent } from 'vue'
import ChevronDownIcon from '@/assets/icons/ChevronDown.vue'

export default defineComponent({
  name: 'LangSelectButton',
  emits: ['update-menu-visibility'],
  components: {
    ChevronDownIcon
  },
  props: {
      name: String,
      showOptions: Boolean
  },
  setup(props, { emit }) {
    const toggleOptions = () => {
      emit('update-menu-visibility', !props.showOptions)
    }

    return {
      toggleOptions,
    }
  }
})
