
import Logo from "@/assets/Logo.vue"

export default {
  name: 'Poster',
  components: {
    Logo
  },
  props: {
    name: String,
    src: String
  }
}
