
import { ref } from 'vue'
import Search from '@/components/Search.vue'
import SearchResults from '@/components/SearchResults.vue'
import { MediaType } from '@/ts/media'

export default {
  name: 'Home',
  components: {
    Search,
    SearchResults
  },
  setup() {
    const query = ref('')
    const mediaType = ref(MediaType.MOVIE)

    const updateQuery = (value: string) => {
      query.value = value
    }

    const updateType = (newType: MediaType) => {
      mediaType.value = newType
    }

    return {
      updateQuery,
      updateType,
      query,
      mediaType
    }
  }
}
