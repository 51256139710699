
import Poster from './Poster.vue'

export default {
  name: 'Media',
  components: {
    Poster
  },
  props: {
    data: Object
  }
}
