
import { computed, defineComponent, onBeforeUpdate, onMounted, onUnmounted, ref, watch } from 'vue'
import { LangName, LangCode } from '@/ts/languages'

export default defineComponent({
  name: 'LangSelectOptions',
  emits: [
    'update-lang',
    'update-menu-visibility'
  ],
  props: {
    showOptions: Boolean,
    availableLangs: Array
  },
  setup(props, { emit }) {
    const preferredLangCode = localStorage.preferredLangCode as LangCode
    const code = ref(preferredLangCode || LangCode.ENGLISH)
    const searchLang = ref('')
    const options = ref<Array<HTMLParagraphElement>>([])

    const setLang = (newCode: LangCode) => {
      code.value = newCode
      emit('update-lang', newCode)
      emit('update-menu-visibility', false)
    }

    const handleKbrd = (e: KeyboardEvent) => {
      // close on escape
      if (e.keyCode === 27) {
        emit('update-menu-visibility', false)
        return
      }

      if (!props.showOptions || e.key.length > 1) return

      const onlyLetters = /[a-zA-Z]/g
      if (!onlyLetters.test(e.key)) return

      searchLang.value += e.key
    }

    // reset the resultItems before each update
    onBeforeUpdate(() => {
      options.value = []
    })

    onMounted(() => {
      window.addEventListener('keydown', handleKbrd)
    })

    onUnmounted(() => {
      window.removeEventListener('keydown', handleKbrd)
    })

    watch(() => searchLang.value, (value) => {
      const filteredLanguages = options.value.filter(e => {
        const langText = e.childNodes[0].textContent?.toLowerCase()
        const enteredValue = value.toLowerCase()

        return langText?.includes(enteredValue)
      })

      filteredLanguages[0]?.scrollIntoView({
        behavior: 'smooth'
      })

      filteredLanguages[0]?.focus()
    })

    watch(() => props.showOptions, () => {
      searchLang.value = ''
    })

    // TODO: written with intent to work, needs to be polished and re-written
    const availableLangs = computed(() => {
      if (!props.availableLangs) return;

      const langs: {[x: string]: LangName} = {}

      for (const lang of props.availableLangs) {
        const code = (lang as LangCode).toUpperCase()

        langs[code] = LangName[code as keyof typeof LangName]
      }

      return langs
    })

    return {
      code,
      setLang,
      languages: availableLangs.value || LangName,
      options
    }
  }
})
