
import { defineComponent } from 'vue';
import DownloadIcon from '@/assets/icons/Download.vue'

export default defineComponent({
  name: 'Subtitles',
  components: {
    DownloadIcon
  },
  props: {
    subtitles: Array
  }
})
