
import { defineComponent } from 'vue'
import SadFace from '@/assets/SadFace.vue'

export default defineComponent({
  name: 'ErrMessage',
  components: {
    SadFace
  },
  props: {
    visible: Boolean,
    msg: String
  }
})
