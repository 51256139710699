
import { defineComponent, ref } from 'vue'
import Glasses from '@/assets/Glasses.vue'
import LangSelect from './LangSelect/Index.vue'
import { MediaType } from '@/ts/media'
import SearchIcon from '@/assets/icons/Search.vue'

export default defineComponent({
  components: {
    Glasses,
    LangSelect,
    SearchIcon
  },
  emits: [
    'update-query',
    'update-type'
  ],
  setup(props, { emit }) {
    const query = ref('')
    const filter = ref(MediaType.MOVIE)

    const search = () => emit('update-query', query.value)

    const setFilter = (type: MediaType) => {
      window.scroll(0, 0)
      filter.value = type
      emit('update-type', type)
    }

    return {
      search,
      query,
      filter,
      setFilter,
      LangSelect,
      MediaType
    }
  }
})
