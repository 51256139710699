<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="128" height="88" viewBox="0 0 128 88" aria-label="Logo">
    <g transform="translate(0 -20)">
      <g transform="translate(0 20)" fill="#222831" stroke="#fff" stroke-width="5">
        <rect width="128" height="88" rx="20" stroke="none"/>
        <rect x="2.5" y="2.5" width="123" height="83" rx="17.5" fill="none"/>
      </g>
      <path d="M25.214-48.209a7.557,7.557,0,0,0-6.6,3.465q-2.37,3.465-2.37,9.556,0,12.661,9.631,12.661a19.724,19.724,0,0,0,5.64-.81,56.432,56.432,0,0,0,5.49-1.95v10.021a30.385,30.385,0,0,1-12.421,2.43q-9.931,0-15.226-5.76t-5.3-16.651A26.566,26.566,0,0,1,6.628-47.219a18.354,18.354,0,0,1,7.381-7.936A22.289,22.289,0,0,1,25.334-57.93a31.2,31.2,0,0,1,13.591,3.09l-3.63,9.331a40.815,40.815,0,0,0-4.86-1.92A16.986,16.986,0,0,0,25.214-48.209Zm39.873,0a7.557,7.557,0,0,0-6.6,3.465q-2.37,3.465-2.37,9.556,0,12.661,9.631,12.661a19.724,19.724,0,0,0,5.64-.81,56.432,56.432,0,0,0,5.49-1.95v10.021a30.385,30.385,0,0,1-12.421,2.43q-9.931,0-15.226-5.76t-5.3-16.651A26.566,26.566,0,0,1,46.5-47.219a18.354,18.354,0,0,1,7.381-7.936A22.289,22.289,0,0,1,65.207-57.93,31.2,31.2,0,0,1,78.8-54.839l-3.63,9.331a40.815,40.815,0,0,0-4.86-1.92A16.986,16.986,0,0,0,65.087-48.209Z" transform="translate(22.202 99.09)" fill="#de7b5a"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo'
}
</script>