<template>
  <div class="sadMouth">
    <svg xmlns="http://www.w3.org/2000/svg" width="60" viewBox="0 0 60 15" aria-label="Sad Mouth Illustration">
      <path d="M15,0H45A15,15,0,0,1,60,15v0a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0v0A15,15,0,0,1,15,0Z" fill="#fff"/>
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SadMouth',
})
</script>

